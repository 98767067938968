// src/pages/payment-workshop-success.js
import React, { useEffect } from "react"
import { navigate } from "gatsby"
import LayoutNoStripe from "../components/layoutNoStripe"
import { SectionHeading } from "../styledComponents/section"
import FormQuantumTouchRegister from "../components/formQuantumTouchRegister"
import { Article, ArticleHeader, ArticleSection } from "../styledComponents/article"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const PaymentSuccessPage = ({ location }) => {
  const { state } = location || {}
  console.log('state', state)
  // Redirect if missing info
  // useEffect(() => {
  //   if (!state?.name || !state?.price) {
  //     navigate("/")
  //   }
  // }, [state])

  // Tag user in Mailchimp
  useEffect(() => {
    if (!state?.email || !state?.name || !state?.date) return
  
    const tagKey = `mailchimpTagged::${state.name}-${state.date}`
    const alreadyTagged = localStorage.getItem(tagKey)
  
    if (alreadyTagged) return
  
    const tagUserInMailchimp = async () => {
      try {
        const res = await fetch("/api/mailchimp-subscribe", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: state.email,
            eventTitle: state.name,
            eventDate: state.date,
          }),
        })
  
        if (res.ok) {
          localStorage.setItem(tagKey, "true")
        }
      } catch (err) {
        console.error("Mailchimp tag error:", err)
      }
    }
  
    tagUserInMailchimp()
  }, [state])

  if (!state) return null

  return (
    <LayoutNoStripe displayNavBorder location={location}>
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader noMargin>
          <h1 itemProp="headline">Workshop Payment Success</h1>
        </ArticleHeader>
        <ArticleSection itemProp="articleBody">
          <p>Thank you, {state.payer}!</p>
          <p>You’ve registered for Karina's: <strong>{state.name}</strong> workshop on <strong>{state.date}</strong></p>
          <p>Total Paid: <strong>£{state.price}</strong></p>
          <h2>Quantum-Touch Registration Form</h2>
          <p>Please can you fill in the following form, this is required from the Quantum-Touch head office.</p>
          <FormQuantumTouchRegister
            firstName={state.payer}
            email={state.email}
          />
        </ArticleSection>
      </Article>
    </LayoutNoStripe>
  )
}

export default PaymentSuccessPage